import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { CloudDownload } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { useEffect } from "react";

// import { useRef } from "react";
// import { useState, useEffect } from "react";

// MUI table custom styling 
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#353740",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// Table column breakdown
const costAnalysisColumns = [
  {
    id: "SECT_ID",
    label: "SECT_ID",
    scenario: 0,
  },
  {
    id: "ST_NAME",
    label: "ST_NAME",
    scenario: 0,
  },
  {
    id: "ST_LENGTH",
    label: "ST_LENGTH",
    scenario: 0
  },
  {
    id: "importance",
    label: "Importance",
    aggregate: {
      "centrality_distance": "widthRW",
      "centrality_time": "pciRW",
      "centrality_population": "curbRW",
      "centrality_width": "sidewalkRW"
    },
    scenario: 0,
  },
  {
    id: "width_rating",
    label: "Width Rating",
    scenario: 0,
  },
  {
    id: "curb_rating",
    label: "Curb Rating",
    scenario: 0,
  },
  {
    id: "sidewalk_rating",
    label: "Sidewalk Rating",
    scenario: 0
  },
  {
    id: "pci_rating",
    label: "PCI",
    scenario: 0
  },
  {
    id: "cost_curb",
    label: "Repair Curb",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_widening",
    label: "Widen",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_sidewalk",
    label: "Add Sidewalk",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_widening_20",
    label: "Widen To 20",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_widening_24",
    label: "Widen To 24",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_resurface",
    label: "Resurface",
    minWidth: 140,
    scenario: 1,
  },
  {
    id:"cost_reconstruction",
    label: "Reconstruction",
    minWidth: 140,
    scenario: 1
  },
];

/*
Cost Table Component

* Provides pagination feature for table data
* Provides control over table granularity
* Provides functionality to select various scenarios, and remove rows from table
* Sets parameter benefits and costs based on parameter state
*/
export default function StickyHeadTable({
  geoData,
  width,
  deleteRow,
  benefits,
  costs,
  setCost,
  setBenefit,
  updateValues,
  updateSection,
  currSection,
  autoData,
  calculateTotalBenfits
}) {

  var isChecked = false;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Function to address page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  useEffect(() => {
    console.log(autoData)
  }, [autoData])

  // Function to handle change in page granularity
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Function to handle downloading file from S3 Bucket.
  const handleDownload = async (fileName) => {
    const fileUrl = 'https://potree-bucket.s3.us-east-2.amazonaws.com/spreadsheets/' + fileName + '.csv'; // All the roads will pull from this for now.

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      saveAs(blob, fileName + ".xlsx");
    }

    catch (error) {
      console.error('Error downloading the file:', error);
    }
  };



  return geoData == null ? (
    <div></div>
  ) : (
    <Paper sx={{ width: width, overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell key={"_delete"} />
              {costAnalysisColumns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {geoData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.SECT_ID}
                    sx={{ backgroundColor: currSection === row.SECT_ID ? "#c3c6d6" : "#ffffff" }}
                  >
                    <StyledTableCell key={"_delete"}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          deleteRow(row.SECT_ID);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          updateSection(row.SECT_ID);
                        }}
                      >
                        <NotListedLocationIcon />
                      </IconButton>

                      <IconButton
                        // aria-label="delete"
                        onClick={() => {
                          handleDownload(row.SECT_ID)
                        }}
                      >
                        <CloudDownload />
                      </IconButton>
                    </StyledTableCell>
                    {costAnalysisColumns.map((column) => {

                      let value = row[column.id];

                      console.log(column.id + value)
                      if (
                        column.id === "cost_curb" ||
                        column.id === "cost_widening" ||
                        column.id === "cost_sidewalk" ||
                        column.id === "cost_resurface" ||
                        column.id === "cost_widening_20" ||
                        column.id === "cost_widening_24" 
                        // column.id === "cost_reconstruction" this should be added back once the reconstruction value is added to the actual dataframe.
                      ) {
                        isChecked = autoData[index]?.[column.id].checked;
                      }

                      if(column.id === "cost_reconstruction") {
                        value = autoData[index]?.[column.id].value;
                        isChecked = autoData[index]?.[column.id].checked;
                      }




                      return (
                        <StyledTableCell key={column.id} align={column.align}>
                          {(() => {
                            if (column.id.includes("cost")) {
                              if(value === 0) {
                                return 0
                              }
                              return Math.trunc(value);
                            } else if (column.id !== "SECT_ID" && typeof value === "number") {
                              return value.toFixed(6);
                            }
                            else {
                              return value;
                            }

                          })()}
                          {column.scenario ? (
                            <Checkbox
                              checked={!!isChecked}
                              onChange={(e) => {

                                // isChecked = !isChecked;

                                const item = autoData[index]?.[column.id];

                                if (item) {
                                  item.checked = !item.checked;
                                }

                                calculateTotalBenfits();


                              }}
                            />
                          ) : null}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 200]}
        component="div"
        count={geoData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
